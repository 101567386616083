

import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
        IonGrid, IonCol, IonRow, IonButtons, IonButton, IonIcon,
        IonCard, IonCardHeader, IonCardContent, IonItem, IonBackButton,
        IonLabel, IonInput, IonSelect, IonSelectOption,
        loadingController, } from '@ionic/vue';
import LogoImg from '@/components/LogoImg.vue';

// icons
import { logIn, close, mail, key, globe, } from 'ionicons/icons';
import { ref } from 'vue';

import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';

// services
import AuthService from '@/services/AuthService';

export default {
  name: 'LoginPage',
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage,
                IonGrid, IonCol, IonRow, IonButtons, IonButton, IonIcon,
                IonCard, IonCardHeader, IonCardContent, IonItem, IonBackButton,
                IonLabel, IonInput, IonSelect, IonSelectOption, LogoImg, },
  setup() {
    const loginEmail = ref("");
    const loginPsw = ref("");
    const pswInput = ref(null);

    // methods
    const { t, locale } = useI18n();
    const router = useRouter();
    const { presentToast, focusInputField } = utils();

    const doLogin = async (email: string, password: any) => {
      if (email && password) {
        const loading = await loadingController.create({});
        await loading.present();
        try {
          await AuthService.doLogin(email, password);
        } catch (e) {
          presentToast(e.message); // wrong email or wrong password
        }
        loading.dismiss();
      } else {
        presentToast(t("RegisterPage.enterEmailAndPassword"), 3000);
      }
    };
    const goToForgotPasswordPage = () => {
      router.replace('/forgot-password'); // go to forgot password page
    }
    const goToRegisterPage = () => {
      router.replace('/register'); // go to registration page
    }

    return {
      t,

      // icons
      logIn, close, mail, key, globe,
      
      // variables
      locale, loginEmail, loginPsw,
      pswInput,

      // methods
      doLogin, goToForgotPasswordPage, goToRegisterPage, focusInputField,
    }
  },
}
